@import "./_variables";

/* ============================================================
  ベーススタイル
============================================================== */

body,html{
  margin: 0;
  bottom: 0;
  width: 100%;
  min-height: 100%;
}
ul,li{
  list-style: none;
  margin: 0;
  padding: 0;
  text-indent: 0;
}
p{
  margin: 0;
  line-height: 1;
}
h1,h2,h3,h4,h5,h6{
  margin: 0;
  padding: 0;
  font-weight: normal;
}
dl,dd,dt{
  margin: 0;
  padding: 0;
}
input{
  &[type="text"],
  &[type="search"],
  &[type="number"],
  &[type="tel"],
  &[type="url"],
  &[type="email"],
  &[type="password"]{
    box-shadow: none;
    padding: 0;
    margin: 0;
    border: none;
    padding: 0 15px;
    font-size: 13px;
    line-height: 1.2;
    letter-spacing: 0.04em;
    outline: none;
    box-sizing: border-box;
    -moz-box-sizing: border-box;
    -webkit-box-sizing: border-box;
    -webkit-appearance: none;
    max-width: 100%;
    &::placeholder{
      font-size: 13px;
      color: #aaa;
    }
  }
}
button{
  background: none;
  border: none;
  box-shadow: none;
  padding: 0;
  margin: 0;
  -webkit-appearance: none;
  outline: none;
  cursor: pointer;
}