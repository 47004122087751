@import "./variables";
@import "./sanitize";
@import "./base";
@import "./general";

/* ============================================================
  共通スタイル
============================================================== */
*{
  box-sizing: border-box;
}
body{
  color: $color-text;
  font-family: $font-r, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}
a{
  text-decoration: none;
  color: inherit;
}
input{
  font-family: $font-r, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}
input[type=text]::-ms-clear {
  display: none;
}

/*
 ローダー用アニメーション
------------------------------------------- */
@-webkit-keyframes loaderAnim {
  0% {
    -webkit-transform: rotate(0deg);
            transform: rotate(0deg);
  }
  100% {
    -webkit-transform: rotate(360deg);
            transform: rotate(360deg);
  }
}
@keyframes loaderAnim {
  0% {
    -webkit-transform: rotate(0deg);
            transform: rotate(0deg);
  }
  100% {
    -webkit-transform: rotate(360deg);
            transform: rotate(360deg);
  }
}