//color
$color-text: #333;
$color-pink: #F15B6B;
$color-gray: #999;
$color-btn-pink: #F15B6B;
$color-btn-gray: #999;
$color-border-gray: #ddd;
$color-rank-platinum : #8aa9c6;
$color-rank-gold : #e5b72e;
$color-rank-bronze : #c28648;
$color-rank-silver : #bcbcbc;
$color-rank-other : #999;
$color-bg-gray: #f2f2f2;
$color-form-white: #fff;
$color-form-black: #333;
$color-form-gray: #AAB2BE;
$color-form-beige:#E0D1AD;
$color-form-brown:#81604C;
$color-form-orange:#F98140;
$color-form-yellow:#EFD234;
$color-form-red:#F15B6B;
$color-form-pink:#FDA2EC;
$color-form-purple:#AD8EEF;
$color-form-green:#9ED563;
$color-form-blue:#4DBEE9;
$color-form-gold: linear-gradient(#dab724 0%, #f0dc8a 100%);
$color-form-silver:linear-gradient(#cacaca 0%, #acacac 100%);
$color-label-coupon: #E2A23B;

//font
$font-r: "Hiragino Kaku Gothic ProN", "ヒラギノ角ゴ ProN W3", "メイリオ", Meiryo, "ＭＳ Ｐゴシック", "MS PGothic", sans-serif;
$font-min: 'Noto Serif JP', serif;
$font-en: Arial, 'Helvetica', 'Helvetica Neue', -apple-system, BlinkMacSystemFont, "Hiragino Kaku Gothic ProN", "ヒラギノ角ゴ ProN W3", "メイリオ", Meiryo, "ＭＳ Ｐゴシック", "MS PGothic", sans-serif;

//width & height
$width-min-pc: 1024px;
$width-max-sp: 560px;
$height-header-pc: 60px;
$height-header-sp: 60px;

//prefix
$prefix: "hpplus_";

//transition
$transition-hover-arrow: transform .3s;
$transform-hover-arrow-right: translate(4px,0);
$transform-hover-arrow-left: translate(-4px,0);
$transform-hover-slider-arrow-right: translate(-16px,-20px);
$transform-hover-slider-arrow-left: translate(-24px,-20px) rotate(180deg);
$transform-hover-slider-arrow-right2: translate(4px,-50%);
$transform-hover-slider-arrow-left2: translate(-4px,-50%) rotate(180deg);